import React from "react";
import {Form, Rate} from 'antd';
import {FireOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

function LivingN(props) {
    const {t} = useTranslation();
    let formData = JSON.parse(localStorage.getItem('formData'));
    if (formData['LivingN'] === undefined) {
        formData = {...formData, ...{"LivingN": {}}}
        localStorage.setItem('formData', JSON.stringify(formData));
    }

    const customIcons = {
        1: <FireOutlined/>,
        2: <FireOutlined/>,
        3: <FireOutlined/>,
        4: <FireOutlined/>,
        5: <FireOutlined/>,
    };

    return (
        <div className="questions-container">

            <div className="question">
                <h3>{t('survey.living.ln-01')}</h3>
                <Form.Item
                    name="ln-01"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.living.ln-02')}</h3>
                <Form.Item
                    name="ln-02"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.living.ln-03')}</h3>
                <Form.Item
                    name="ln-03"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.living.ln-04')}</h3>
                <Form.Item
                    name="ln-04"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.living.ln-05')}</h3>
                <Form.Item
                    name="ln-05"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

        </div>
    );

}


export default LivingN;