import React from "react";
import {useSearchParams} from "react-router-dom";
import {Typography} from 'antd';


const {Title, Paragraph} = Typography;

function Success(props) {
    /*
    http://brn.local:3000/success?payment_intent=pi_3OjKVdJmP4IjXeiv1MR0XMIO&payment_intent_client_secret=pi_3OjKVdJmP4IjXeiv1MR0XMIO_secret_kraMA3g6eGEBBA2d0uoBUS94g&redirect_status=succeeded
    */

    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.get("redirect_status") === "succeeded") {
        //localStorage.setItem('currentFrom', '"Success"');
        localStorage.clear();
    }

    const orderId = searchParams.get("payment_intent")


    return (
        <div className="success-page">
            <Title>We are generating your report!</Title>
            <p level={3}><strong>You will receive an email with an attachment once you confirm your email!</strong></p>
            <p>
                <i>
                    order id: {orderId}<br/>
                    once confirm your email; if you don't receive your report in 10 minutes, please contact
                    support@burnoutornot.com
                    and inform the email address used on the registration form and the order id above.
                </i>
            </p>

        </div>
    );
}


export default Success;