import React, {useEffect, useState} from "react";
import {Button, Checkbox, Divider, Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';

function End(props) {
    const {t} = useTranslation();
    const [selfAssessmentId, setSelfAssessmentId] = useState(localStorage.getItem('assessmentId'));
    const [canSubmit, setCanSubmit] = useState(false);

    let formData = JSON.parse(localStorage.getItem('formData'));

    useEffect(() => {
        if (selfAssessmentId === null) {
            const fetchData = async () => {
                const data = await fetch(`${process.env.REACT_APP_API_URI}/assessment-data`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(formData)
                });

                const json = await data.json();
                setSelfAssessmentId(json.id);
                localStorage.setItem('assessmentId', json.id);
            };

            fetchData()
                .catch(console.error);
        }
        return () => false;
        // eslint-disable-next-line
    }, []);

    const onChangeTermsAndConditions = (e) => {
        localStorage.setItem('terms_and_conditions', e.target.checked);
        setCanSubmit(e.target.checked);
    };

    const onChangeNewsletterOptIn = (e) => {
        localStorage.setItem('marketing_opt_in', e.target.checked);
    }

    return (
        <div className="end">
            <h1>Congratulations, you dit it!</h1>
            <p>You are one small step away from seeing your <b>Basic Burnscore Report</b>.</p>
            <Form.Item
                label={t('end_form.email_field.label')}
                name="email"
                rules={[
                    {
                        required: true,
                        message: t('end_form.email_field.error'),
                    },
                ]}
            >
                <Input style={{width: 'calc(100% - 200px)'}} placeholder={t('end_form.email_field.placeholder')}
                       type="email"/>
            </Form.Item>

            <Form.Item
                label={t('end_form.first_name.label')}
                name="first_name"
                rules={[
                    {
                        required: true,
                        message: t('end_form.first_name.error'),
                    },
                ]}
            >
                <Input style={{width: 'calc(100% - 200px)'}} placeholder={t('end_form.first_name.placeholder')}
                       type="text"/>
            </Form.Item>
            <Form.Item
                label={t('end_form.last_name.label')}
                name="last_name"
                rules={[
                    {
                        required: true,
                        message: t('end_form.last_name.error'),
                    },
                ]}
            >
                <Input style={{width: 'calc(100% - 200px)'}} placeholder={t('end_form.last_name.placeholder')}
                       type="text"/>
            </Form.Item>

            <Divider/>

            <Form.Item
                valuePropName="newsletter_optin"
            >
                <Checkbox id="newsletter_optin" onChange={onChangeNewsletterOptIn}/>
                <label
                    htmlFor="newsletter_optin">&nbsp; I want to receive Coupons, Discounts, Articles, Promotions and <b>eventual
                    freebies
                    .</b></label>
            </Form.Item>

            <Form.Item
                valuePropName="terms_and_conditions"
            >
                <Checkbox id="terms_and_conditions" onChange={onChangeTermsAndConditions}/>
                <label
                    htmlFor="terms_and_conditions"><i>&nbsp; I accept the <a
                    href="/legal#terms-and-conditions"
                    target="_blank">terms and
                    conditions</a> and the <a href="/legal#privacy-policy"
                                              target="_blank">privacy policy</a>,</i> and I'm aware a free account will
                    be created for me with my email address.</label>
            </Form.Item>

            <Divider/>

            <div className="end_submit">
                {canSubmit ?
                    <Button type="primary" htmlType="submit">{t('end_form.submit_button')}</Button>
                    :
                    <Button type="primary" htmlType="submit" disabled>{t('end_form.submit_button')}</Button>
                }
            </div>
        </div>
    );

}

export default End;