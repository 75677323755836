import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Typography} from "antd";
import {useTranslation} from 'react-i18next';


function Consent(props) {
    const {Title, Paragraph} = Typography;
    const {t} = useTranslation();
    const {consent} = useParams();
    const consent_params = window.atob(consent).split('#')
    const user_id = consent_params[0]
    const consent_enum = consent_params[1]
    const consent_token = consent_params[2]
    const consent_toggle = consent_params[3]
    const consent_toggle_boolean = consent_params[3] === 'True' ? true : false
    const action_string = consent_toggle_boolean ? 'subscribed' : 'unsubscribed'
    const [consentData, setConsentData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`${process.env.REACT_APP_API_URI}/c/${consent}`, {
                method: "GET",
            }).then(data => setConsentData(data));


        };


        fetchData()
            .catch(console.error);
        return () => false;
    }, []);

    return (

        <div className="success-page">

            {consentData.status === 304 || consentData.status === 200 ? (<>
                <Title>{t('consent.email_consent.' + consent_enum + '.title')}</Title>
                <p><strong>{t('consent.email_consent.' + consent_enum + '.' + action_string)}</strong></p>
            </>) : (<></>)}
        </div>

    );
}


export default Consent;