import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Button, Typography} from "antd";
import {useTranslation} from 'react-i18next';


function Download(props) {
    const {Title, Paragraph} = Typography;
    const {t} = useTranslation();
    const {download} = useParams();
    const download_params = window.atob(download).split('#')
    const user_id = download_params[0]
    const survey_id = download_params[1]
    const consent_token = download_params[2]
    //let api_url = process.env.REACT_APP_API_URI
    let api_url = 'https://stg-api.burnoutornot.com'
    const download_uri = `${api_url}/survey/download/${download}`

    console.log(download_params)
    console.log(download)

    useEffect(() => {
        const fetchData = async () => {
            // //let api_url = process.env.REACT_APP_API_URI
            // let api_url = 'https://stg-api.burnoutornot.com'
            // const data = await fetch(`${api_url}/survey/download/${download}`, {
            //     method: "GET",
            // });


        };


        fetchData()
            .catch(console.error);
        return () => false;
    }, []);

    return (

        <div className="success-page">

            <Title>You self-assessment results are ready to be downloaded</Title>
            <p><strong>The download should initiate automatically, and in case it does not happen, you can click on the
                button below</strong></p>
            <div className="button-wrap">
                <Button type="primary" href={download_uri} size={'large'}>DOWNLOAD NOW!</Button>
            </div>

        </div>

    );
}


export default Download;