import React from 'react';
import {Button, Form, Popover, Steps} from 'antd';


import WorkSituationN from './WorkSituationN';
import FamilyFriendsN from './FamilyFriendsN';
import LivingN from './LivingN';
import EducationN from './EducationN';
import EntertainmentWellnessN from './EntertainmentWellnessN';
import {useNavigate} from "react-router-dom";


import End from './End';
import Seo from "../Seo";

const customDot = (dot, {status, index}) => (
    <Popover
        content={
            <span>
        step {index} status: {status}
      </span>
        }
    >
        {dot}
    </Popover>
);

function Assessment() {
    const navigate = useNavigate();
    if (localStorage.getItem('clientSecret') !== null) {
        window.location.href = '/checkout';
    }
    const formList = [
        WorkSituationN,
        FamilyFriendsN,
        LivingN,
        EducationN,
        EntertainmentWellnessN,
        End,
    ];

    let [currentForm, setCurrentForm] = React.useState(
        JSON.parse(localStorage.getItem('currentFrom')) || formList[0].name
    );

    localStorage.setItem('currentFrom', JSON.stringify(currentForm));

    if (!JSON.parse(localStorage.getItem('formData'))) {
        localStorage.setItem('formData', JSON.stringify({}));
    }

    const nextStep = () => {
        let nextFormIndex = formList.findIndex((f) => {
            return f.name === currentForm;
        }) + 1;

        let nexForm = nextFormIndex >= formList.length ? formList[nextFormIndex - 1].name : formList[nextFormIndex].name;

        setCurrentForm(nexForm);
        configureCurrentFormElement(currentForm);
        localStorage.setItem('currentFrom', JSON.stringify(currentForm));
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };
    const onFinish = (values) => {
        if (currentForm === "End") {
            localStorage.setItem('email', values.email);
            localStorage.setItem('first_name', values.first_name);
            localStorage.setItem('last_name', values.last_name);
            navigate('/checkout');
            return;
        }

        let formData = JSON.parse(localStorage.getItem('formData'));

        formData[currentForm] = {...formData[currentForm], ...values};
        localStorage.setItem('formData', JSON.stringify(formData));

        nextStep();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    let CurrentForm = currentForm;


    const configureCurrentFormElement = (formElement) => {
        // eslint-disable-next-line
        const result = formList.map((comp) => {
            if (comp.name === formElement) {
                CurrentForm = comp;
            }
        });
    }

    configureCurrentFormElement(currentForm);

    const getCurrentFormProgressIndex = () => {
        for (let key in formList) {
            if (formList[key].name === currentForm) {
                return key;
            }
        }
    }

    return (

        <Form name="burnoutform"
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError={true}
              onFinishFailed={onFinishFailed}>
            <Seo title="Start your free burnout survey - be in controll, now!"
                 description="Use the free survey to reflect about yourself so you can wakeup for a balanced life"
                 name="Burnoutornot"
                 type="surveys"/>
            {currentForm !== "End" ?
                <Steps
                    current={getCurrentFormProgressIndex()}
                    progressDot={customDot}
                    items={[
                        {
                            title: 'Work'
                        },
                        {
                            title: 'Family & Friends'
                        },
                        {
                            title: 'Home & Living'
                        },
                        {
                            title: 'Education & Development'
                        },
                        {
                            title: 'Entertainment'
                        },
                    ]}
                /> : ""
            }
            <CurrentForm></CurrentForm>

            {/* <Form.Item>
            <Button onClick={prevStep} type="secondary">Prev</Button>
        </Form.Item> */}
            {currentForm !== "End" ?
                <Form.Item className="next-button-wrapper">
                    <Button className="form-next-button" type="primary" htmlType="submit" size="large">Next</Button>
                </Form.Item>
                : ""
            }


        </Form>

    );
};

export default Assessment;