import React from "react";
import {Form, Rate} from 'antd';
import {FireOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

function FamilyFriendsN(props) {
    const {t} = useTranslation();
    let formData = JSON.parse(localStorage.getItem('formData'));
    if (formData['FamilyFriendsN'] === undefined) {
        formData = {...formData, ...{"FamilyFriendsN": {}}}
        localStorage.setItem('formData', JSON.stringify(formData));
    }

    const customIcons = {
        1: <FireOutlined/>,
        2: <FireOutlined/>,
        3: <FireOutlined/>,
        4: <FireOutlined/>,
        5: <FireOutlined/>,
    };

    return (
        <div className="questions-container">

            <div className="question">
                <h3>{t('survey.family_friends.fn-01')}</h3>
                <Form.Item
                    name="fn-01"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.family_friends.fn-02')}</h3>
                <Form.Item
                    name="fn-02"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.family_friends.fn-03')}</h3>
                <Form.Item
                    name="fn-03"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.family_friends.fn-04')}</h3>
                <Form.Item
                    name="fn-04"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.family_friends.fn-05')}</h3>
                <Form.Item
                    name="fn-05"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

        </div>
    );

}


export default FamilyFriendsN;