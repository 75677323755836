import "./Home.scss"
import React from "react";
import {Button, Image} from "antd";
import Seo from "../Seo";


function Home(props) {


    return (

        <div>
            <Seo title="Burnout or not?, think about yourself, "
                 description="The interactive way to learn more about where you stand, and propel you to a balanced work-life standing. If you feel or not burnout, take 5 minutos to for youself, for free!"
                 name="Burnoutornot"
                 type="surveys"/>
            <section className={'home-hero'}>
                <div className="hero-content">

                    <div className="hero-text">
                        <h1>Foggy greasy mind? Are you feeling a drag?</h1>
                        <p>Get to know your <b>BURNSCORE</b> for free!</p>

                    </div>
                    <div className="hero-image">
                        <Image src={process.env.PUBLIC_URL + '/images/brain_greasy.webp'} preview={false}/>
                    </div>
                    <div className="button-wrap">
                        <Button type="primary" href="/survey" size={'large'}>Learn my BURNSCORE now, <i> it is
                            free!</i></Button>
                    </div>
                </div>


            </section>

            <section className="what-is-burnout">
                <article>
                    <h2>What is Burnout?</h2>

                    <p>
                        Burnout syndrome, as characterized by the World Health Organization (WHO), is a syndrome
                        conceptualized as resulting from chronic workplace stress that has not been successfully
                        managed. It is specifically tied to the occupational context and is distinguished from other
                        mental health conditions. The WHO provides a clear framework for identifying burnout,
                        emphasizing that it is not classified as a medical condition but rather as an occupational
                        phenomenon. This distinction is critical in understanding how burnout is perceived and addressed
                        within the healthcare and professional domains.</p>

                    <h3>Key Dimensions of burnout syndrome according to WHO</h3>

                    <ul>
                        <li>Feelings of energy depletion or exhaustion: This is the core emotional component of burnout,
                            where individuals feel a significant drop in energy, fatigue, and a sense of being
                            emotionally drained due to continuous exposure to workplace stressors.
                        </li>
                        <li>Increased mental distance from one’s job, or feelings of negativism or cynicism related to
                            one's job: This dimension reflects a change in one’s attitude towards their work, where
                            there is a noticeable detachment or a pessimistic view of the job and its value, often as a
                            protective measure to cope with the stress.
                        </li>
                        <li>Reduced professional efficacy: Burnout leads to feelings of ineffectiveness and a lack of
                            accomplishment at work. Individuals may experience a decline in productivity and
                            performance, contributing to a sense of failure and inadequacy.
                        </li>
                    </ul>

                    <h2>The Burnoutscore</h2>

                    <p>The Burnscore is an innovative self-assessment tool designed to support individuals in evaluating
                        their risk of burnout syndrome across a broader spectrum of life domains than traditional
                        assessments typically consider. Recognizing that burnout can extend beyond the occupational
                        context, the Burnscore methodically reflects five critical dimensions of an individual's life:
                        Work, Family & Friends, Living, Education, and Entertainment. This comprehensive approach
                        acknowledges the multifaceted nature of stress and its potential to arise from various sources,
                        not solely from the workplace.</p>
                    <p>The Burnscore offers a nuanced perspective on aspects that can contribute to burnout, encouraging
                        individuals to consider how
                        various aspects of their lives contribute to their overall stress levels and risk of burnout. By
                        addressing these five dimensions, the Burnscore aims to empower individuals with a more holistic
                        understanding of their well-being, enabling them to identify specific areas of concern and take
                        targeted actions to mitigate stress. This tool highlights the importance of a balanced approach
                        to life, where work, relationships, personal growth, and leisure are all recognized as essential
                        components of a healthy, fulfilling life.</p>
                </article>
            </section>
        </div>

    );
}


export default Home;