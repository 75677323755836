import React from "react";
import {Helmet} from 'react-helmet-async';

function Imprint(props) {

    return (
        <div id="imprint">
            <Helmet>
                { /* Standard metadata tags */}
                <title>Imprint</title>
                { /* Indexing configuration */}
                <meta name="robots" content="noindex,nofollow"/>
                { /* End Indexing configuration */}
            </Helmet>
            <h2>Imprint</h2>
            <p>Service Provider<br/>
                Name of the company: Berbry UG (haftungsbeschränkt)<br/>
                Address: Pufendorfstr. 4A<br/>
                10249, Berlin, Germany<br/>
                Email: support@burnoutornot.com<br/>
            </p>


            <p> Legal Representation<br/>
                Managing Directors: Danilo Gomes de Fretas<br/>
                Company Registration Number: HRB 231165 B<br/>
                Place of Registration: District Court of Charlottenburg (Berlin - Germany)<br/>
                VAT ID: NO VAT COLLECTED (<i>Kleinunternehmer</i>/small business) bellow 22.000 EUR
                revenue in the previous fiscal year or below 50.000,00 EUR in the current fiscal year.</p>


            <strong>Terms and Conditions:</strong>
            <p>Please refer to our <a href="/terms-and-conditions">Terms and Conditions</a> page for detailed
                information regarding the use of Burnoutornot.com.</p>

            <strong>Privacy Policy:</strong>
            <p>For information on how we collect, process, and protect your personal data, please visit our <a
                href="/privacy-policy">Privacy Policy</a> page.</p>

            <strong>Copyright Notice:</strong>
            <p>Copyright &copy; 2023 Berbry UG (haftungsbeschränkt). All rights reserved. The content and works
                published on
                this website are governed by the copyright laws of Germany. Duplication, processing, distribution, or
                any form of commercialization of such material beyond the scope of copyright law shall require the prior
                written consent of its respective author or creator.</p>

            <strong>Disclaimer:</strong>
            <p>The information, product, tools, books, and other form of digital content provided on Burnoutornot.com is
                for general and educational purposes only. We make every
                effort to ensure the accuracy and reliability of the information provided but do not guarantee that it
                is complete, up-to-date, or suitable for any particular purpose, in special we do not recommend the
                content or tools of the website as medical/professional advice for mental health. We accept no liability
                for loss or
                damage arising from the use of this site, use this website at your own discretion.</p>

            <strong>External Links:</strong>
            <p>This website may contain links to external websites. We are not responsible for the content or privacy
                practices of external websites.</p>

            <strong>Appointed Internal Data Protection Officer:</strong>
            <p>Data Protection Officer: Danilo Gomes de Freitas<br/>
                Email: support@burnoutornot.com<br/>
                Address: same as company</p>

            <strong>Note:</strong>
            <p>This imprint fulfills the requirements set forth by the German Telemedia Act (TMG). For any queries or
                further information, please contact via the provided contact
                details.</p>
        </div>

    );
}


export default Imprint;