import React from "react";
import {Form, Rate} from 'antd';
import {FireOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

function EducationN(props) {
    const {t} = useTranslation();
    let formData = JSON.parse(localStorage.getItem('formData'));
    if (formData['EducationN'] === undefined) {
        formData = {...formData, ...{"EducationN": {}}}
        localStorage.setItem('formData', JSON.stringify(formData));
    }

    const customIcons = {
        1: <FireOutlined/>,
        2: <FireOutlined/>,
        3: <FireOutlined/>,
        4: <FireOutlined/>,
        5: <FireOutlined/>,
    };

    return (
        <div className="questions-container">

            <div className="question">
                <h3>{t('survey.education.en-01')}</h3>
                <Form.Item
                    name="en01"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.education.en-02')}</h3>
                <Form.Item
                    name="en02"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.education.en-03')}</h3>
                <Form.Item
                    name="en03"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.education.en-04')}</h3>
                <Form.Item
                    name="en04"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.education.en-05')}</h3>
                <Form.Item
                    name="en05"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>


        </div>
    );

}


export default EducationN;