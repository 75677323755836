import React, {useEffect} from 'react';
import {Layout, Space, theme} from 'antd';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';

import Assessment from './forms/Assessment';
import Consent from './pages/Consent';
import Download from './pages/Download';
import Checkout from './pages/Checkout';
import Cancelled from './pages/Cancelled';
import Success from './pages/Success';
import Home from './pages/Home';
import './App.scss';
import logo from './logo.svg';
import {useTranslation} from 'react-i18next';

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import ReactGA from "react-ga4";
import Imprint from "./pages/legal/Imprint";
import Support from "./pages/Support";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";

const lngs = {
    en: {nativeName: 'English'},
    "pt-BR": {nativeName: 'Português Brasileiro'}
};

const {Header, Content, Footer} = Layout;


const App = () => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID);
    const {t, i18n} = useTranslation();
    const {
        token: {},
    } = theme.useToken();

    useEffect(() => {
        CookieConsent.run({

            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {}
            },

            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description: 'We use cookies and local storage for storing information necessary for this web application to work properly; and also there information stored for performance analysis and marketing campaigns. You can also check our <a href="/privacy-policy">privacy policy</a> containing the full list of data processors. ',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Only Essentials',
                            showPreferencesBtn: 'Manage Individual preferences'
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close modal',
                            sections: [
                                {
                                    title: '',
                                    description: ''
                                },
                                {
                                    title: 'Only Essentials cookies',
                                    description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'More information',
                                    description: 'For any queries in relation to cookies and your choices, please contact <a href="/support">our support</a>'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }, []);

    return (
        <HelmetProvider>
            <Router>
                <Space direction="vertical" style={{width: '100%'}} size={[0, 48]}>
                    <Layout className="layout" style={{minHeight: "100vh"}}>

                        <Header className={'header-nav'}>
                            <a href="/" className="logo">
                                <img src={logo} alt="React Logo"/>
                            </a>
                        </Header>

                        <Layout>


                            <Content>

                                <div className="site-layout-content">
                                    <Routes>

                                        <Route path="/c/:consent" element={<Consent/>}/>
                                        <Route path="/survey/download/:download" element={<Download/>}/>
                                        <Route path="/survey" element={<Assessment/>}/>
                                        <Route path="/checkout" element={<Checkout/>}/>
                                        <Route path="/success" element={<Success/>}/>
                                        <Route path="/cancelled" element={<Cancelled/>}/>
                                        <Route path="/support" element={<Support/>}/>
                                        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                                        <Route path="/imprint" element={<Imprint/>}/>
                                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                                        <Route path="/" element={<Home/>}/>

                                    </Routes>
                                </div>
                                <Layout>
                                    <Footer className="footer">


                                        <div className="colophon">
                                            {/*
                                            <div className={'language-selector'}>
                                                Language:<br/>
                                                {Object.keys(lngs).map((lng) => (
                                                    <button key={lng}
                                                            style={{fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal'}}
                                                            type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                                        {lngs[lng].nativeName}
                                                    </button>
                                                ))}
                                            </div>
                                                */}
                                        </div>
                                        <ul className="footer-links">
                                            <li><a href="/support">Support</a></li>
                                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                                            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                                            <li><a href="/imprint">Imprint</a></li>
                                        </ul>
                                        <p className="copyright">Burnoutornot Copyright ©2023 - All rights reserved.</p>
                                    </Footer>
                                </Layout>
                            </Content>
                        </Layout>

                    </Layout>
                </Space>
            </Router>
        </HelmetProvider>
    );
};
export default App;