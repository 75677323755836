import React from "react";
import {Form, Rate} from 'antd';
import {FireOutlined} from '@ant-design/icons';
import {useTranslation, Trans} from 'react-i18next';

function EntertainmentWellnessN(props) {
    const {t} = useTranslation();
    let formData = JSON.parse(localStorage.getItem('formData'));
    if (formData['EntertainmentWellnessN'] === undefined) {
        formData = {...formData, ...{"EntertainmentWellnessN": {}}}
        localStorage.setItem('formData', JSON.stringify(formData));
    }
    const customIcons = {
        1: <FireOutlined/>,
        2: <FireOutlined/>,
        3: <FireOutlined/>,
        4: <FireOutlined/>,
        5: <FireOutlined/>,
    };

    return (
        <div className="questions-container">
            <div className="question">
                <h3>{t('survey.health_entertainment.etn-01')}</h3>
                <Form.Item
                    name="etn-01"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.health_entertainment.etn-02')}</h3>
                <Form.Item
                    name="etn-02"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.health_entertainment.etn-03')}</h3>
                <Form.Item
                    name="etn-03"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.health_entertainment.etn-04')}</h3>
                <Form.Item
                    name="etn-04"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.health_entertainment.etn-05')}</h3>
                <Form.Item
                    name="etn-05"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>
        </div>
    );

}


export default EntertainmentWellnessN;