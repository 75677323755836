import React, {useEffect, useState} from "react";
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import {loadStripe} from '@stripe/stripe-js';

import {Divider, Image, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import './Checkout.scss';


const {Title, Paragraph} = Typography;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#c5c5c5',
};


function Checkout(props) {
    const {t} = useTranslation();
    const [clientSecret, setClientSecret] = useState(localStorage.getItem('clientSecret') || "");
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
    const [selfScore, setSelfScore] = useState({});


    useEffect(() => {

        if (clientSecret === null || clientSecret === "") {
            const fetchData = async () => {
                const data = await fetch(`${process.env.REACT_APP_API_URI}/payments/generate`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        "email": localStorage.getItem('email'),
                        "first_name": localStorage.getItem('first_name'),
                        "last_name": localStorage.getItem('last_name'),
                        "survey_id": localStorage.getItem('assessmentId'),
                        "marketing_opt_in": localStorage.getItem('marketing_opt_in'),
                        "terms_and_conditions": localStorage.getItem('terms_and_conditions')
                    })
                });

                const json = await data.json();
                setClientSecret(json.client_secret);
                localStorage.setItem('clientSecret', json.client_secret);
            };


            fetchData()
                .catch(console.error);
        }
        const fetchScore = async () => {
            const data = await fetch(`${process.env.REACT_APP_API_URI}/assessment/score`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    "survey_id": localStorage.getItem('assessmentId'),
                })
            });

            setSelfScore(await data.json());
        };

        fetchScore()
            .catch(console.error);

        return () => false;
    }, []);

    const gaugeFillStyle = {transform: "rotate(" + selfScore.score / 10000 / 2 + "turn)"}

    let options = {
        clientSecret: clientSecret,
    };

    return (
        <section className="checkout">

            <section className={`summary ${selfScore.score_class}`}>
                <Title
                    level={2}>{t('checkout.survey_summary.title')} {selfScore.score} {t('checkout.survey_summary.score_conjunction')} {selfScore.boundary}</Title>

                <div className="gauge">
                    <div className="gauge__body">
                        <div className="gauge__fill" style={gaugeFillStyle}></div>
                        <div className="gauge__cover">{selfScore.score}</div>
                    </div>
                    <div className="gauge__min_max">
                        <span>0</span>
                        <span>{selfScore.boundary}</span>
                    </div>
                </div>
                <ul>
                    <li className="degree">{t(`checkout.survey_summary.class.${selfScore.score_class}.label`)}</li>
                    <li>{t(`checkout.survey_summary.class.${selfScore.score_class}.tip`)}</li>
                </ul>
                <Title level={4}>Attention: THIS IS NOT MEDICAL OR PROFESSIONAL
                    ADVICE</Title>
                <p><i>The content, tools, or any materials provided through the Services/Products are for educational
                    purposes only and do not constitute or intend medical/professional advice.</i></p>
            </section>

            <section className={`payment`}>
                <Title>{t(`checkout.payment.title`)}</Title>
                <p>{t(`checkout.payment.headline`)}</p>
                <Image src={process.env.PUBLIC_URL + '/images/report_sample.png'} preview={false}/>

                <div className="price-tag">
                    Price: <br/>
                    <s>19,90 USD</s>
                    <br/>
                    <span>Only: 9,90 USD</span>
                </div>
                <Divider/>

                {clientSecret !== "" ?
                    <Elements stripe={stripePromise} options={options}>
                        <div className="checkout-from-wrapper">
                            <CheckoutForm/>
                        </div>
                    </Elements>

                    : ''}

                <span className="payment-logo">
                            Secure payments by<br/>
                            <svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg" width="60" height="25"
                                 className="UserLogo variant-- NewsroomInfoLogoGridGraphic__logo"><title>Stripe logo</title><path
                                fill="var(--userLogoColor, #000000)"
                                d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                                fillRule="evenodd"></path></svg>
                        </span>
            </section>
        </section>
    );
}


export default Checkout;