import React from "react";


function Cancelled(props) {

    return (
        <div>
            Cancelled
        </div>
    );
}


export default Cancelled;