import React from "react";
import {Helmet} from "react-helmet-async";

function TermsAndConditions(props) {

    return (
        <div id="terms-and-conditions">
            <Helmet>
                { /* Standard metadata tags */}
                <title>Terms and Conditions</title>
                { /* Indexing configuration */}
                <meta name="robots" content="noindex,nofollow"/>
                { /* End Indexing configuration */}
            </Helmet>
            <h2>Terms and Conditions</h2>
            <h3>Acceptance of Terms</h3>
            <p>By accessing and using Burnoutornot.com, the Burnscore tool, the Burnscore Companion, and any other
                services
                or products (including digital books) provided through the website (collectively referred to as
                "Services"),
                you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, you
                must not use the Services. Burnoutornot.com, Burnscore and its content/services/products are property of
                Berbry UG (haftungsbeschränkt)</p>

            <h3>Description of Services</h3>
            <p>Burnoutornot.com offers users access to the Burnscore and self-assessment tools for
                educational purposes, as well as content and digital books for purchase, also for education purpose.
                These Services and Products are provided "as
                is"
                and liability free for Berbry UG (haftungsbeschränkt) and burnoutornot.com. The Services are intended to
                encourage
                self-reflection and are not a substitute for professional medical or psychological advice, diagnosis, or
                treatment by a qualified/certified professional.</p>

            <h3>Important Disclaimer: BURNOUTORNOT.COM and BURNSCORE ARE NOT MEDICAL/PROFESSIONAL ADVICE</h3>
            <p>The content, tools, or any materials provided through the Services/Products are for educational purposes
                only
                and
                do not constitute or intend medical/professional advice. The creators and contributors of the
                Services/Products do not offer medical or
                health services of any category. Users are encouraged to seek professional advice for any health
                concerns.</p>

            <h3>Limitation of Liability</h3>
            <p>The creators, contributors, in special Berbry UG (haftungsbeschränkt) or its managing director, and any
                parties involved in the creation, production, or delivery of the
                Services/Products on Burnoutornot.com, including (but not limited to) the Burnscore tool, Burnscore
                Companion, and digital content such as books, newsletters, SMS,
                shall
                not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising
                out
                of your access to, use of, inability to use, or reliance on the Services/Products, even if advised of
                the
                possibility
                of such damages.</p>

            <h3>No Warranty</h3>
            <p>The Services are provided "as is" without warranty of any kind, express or implied, including but not
                limited to the warranties of merchantability, fitness for a particular purpose, or non-infringement. The
                creators and contributors do not warrant that the Services will be uninterrupted or error-free, that
                defects
                will be corrected, or that the Services or the server that makes them available are free of viruses or
                other
                harmful components.</p>

            <h3>Delivery of Digital Goodies</h3>
            <p>Burnoutornot.com provides a one time delivery of content via email, the customer has 7 business days to
                contact the support incase any digital goodies were not delivered one time. We keep logs of digital
                products we deliver for any necessary disputes. (support@burnoutornot.com - support working hours Monday
                to Friday from 18:30
                to 22:30 CET time). Support requests may take up to 2 business days to be replied.</p>

            <h3>Intellectual Property Rights</h3>
            <p>All content, materials, and intellectual property related to the Services/Products are owned by or
                licensed to
                Burnoutornot.com (Berbry UG (haftungsbeschränkt)). The content is provided for personal, non-commercial
                use only. Any unauthorized use of
                the
                content or Services/Products may violate intellectual property laws.</p>

            <h3>Amendments to Terms</h3>
            <p>Burnoutornot.com reserves the right to amend these Terms at any time. By continuing to access or use the
                Services after amendments become effective, you agree to be bound by the revised Terms.</p>

            <h3>Governing Law</h3>
            <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction of the
                Court of Berlin Germany, without giving effect to any principles of conflicts of law.</p>

            <h3>Final</h3>
            <p>By using Burnoutornot.com and its Services, you acknowledge that you have read, understood, and agree to
                be
                bound by these Terms and Conditions.</p>

            <h3>Contact Us</h3>
            <p>For any questions regarding these Terms, please contact us through the email address
                support@burnoutornot.com.</p>
        </div>

    );
}


export default TermsAndConditions;