import React from "react";
import {Helmet} from "react-helmet-async";


function Support(props) {

    return (
        <div id="support">
            <Helmet>
                { /* Standard metadata tags */}
                <title>Support</title>
                { /* Indexing configuration */}
                <meta name="robots" content="noindex,nofollow"/>
                { /* End Indexing configuration */}
            </Helmet>
            <h2>Support</h2>
            <ul>
                <li>Office hours: Monday to Friday 18:30 to 22:30 CET via email.</li>
                <li>Response time in general up to two business days, we always work to replay back faster than it.</li>
                <li>support happens via email: support@burnoutornot.com</li>
            </ul>
        </div>

    );
}


export default Support;