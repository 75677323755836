import React from "react";
import {Helmet} from "react-helmet-async";

function PrivacyPolicy(props) {

    return (
        <div id="privacy-policy">
            <Helmet>
                { /* Standard metadata tags */}
                <title>Privacy Policy</title>
                { /* Indexing configuration */}
                <meta name="robots" content="noindex,nofollow"/>
                { /* End Indexing configuration */}
            </Helmet>
            <h2>Privacy Policy for Burnoutornot.com</h2>

            <h3>Introduction</h3>
            <p>Burnoutornot.com is committed to protecting your privacy and handling your data in an open and
                transparent manner. This privacy policy outlines how we collect, use, process, and safeguard the
                personal data of our users in compliance with the General Data Protection Regulation (GDPR). By using
                our services, you consent to the practices described in this policy.</p>

            <h3>Data Controller</h3>
            <p>The data controller responsible for your personal data is Burnoutornot.com, with a legal forum and court
                located in Berlin, Germany. For any data protection inquiries or concerns, you can contact us at <a
                    href="mailto:support@burnoutornot.com">support@burnoutornot.com</a>.</p>

            <h3>Data Processors and Subprocessors</h3>
            <p>We use the following subprocessors to provide our services:</p>
            <ul>
                <li>Stripe Inc., for payment processing services.</li>
                <li>DigitalOcean, for hosting services.</li>
                <li>Google Analytics (Google LLC), for website analytics.</li>
                <li>Grafana Labs AB, for system logs (maximum 30 days retentions)</li>
                <li>Cloudflare, Inc. for HTTP Security, no data is stored, 100% of the traffic is encrypted, cloudflare
                    does not receive plain data.
                </li>
            </ul>
            <p>These subprocessors are carefully chosen to ensure they comply with GDPR and provide adequate protection
                for your data. Our data hosting is based in Europe to align with GDPR requirements.</p>

            <h3>Personal Data Collection and Use</h3>
            <p>We collect personal data to match users with their historical assessment data and
                to manage requests for data retrieval or deletion, as well as meta-information of payment processing
                delivered by the payment processor (Stripe). Personal
                data is processed to provide our services,
                improve application functionality, and for anonymized data analysis to train artificial intelligence
                models.</p>

            <p>We do not receive or store any credit card numbers, and those are directly received by the payment
                processor (Stripe).</p>

            <h3>Data Retention</h3>
            <p>Personal data is stored for as long as necessary to fulfill the purposes for which it was collected,
                or
                as long as the user wishes to retrieve their historical assessment data, in accordance with GDPR
                rules.</p>

            <h3>Rights of the Data Subject</h3>
            <p>Under GDPR, you have the right to access, rectify, delete, or restrict the processing of your
                personal
                data. You also have the right to data portability and the right to object to processing. To exercise
                these rights, please contact us at the address provided.</p>

            <h3>Data Protection</h3>
            <p>We implement technical and organizational measures to ensure a level of security appropriate to the
                risk,
                protecting your personal data from unauthorized access, alteration, disclosure, or destruction.</p>

            <h3>Anonymized Data</h3>
            <p>Anonymized data, stripped of any personally identifiable information, may be used to improve our
                application and for the development of artificial intelligence models. This use is in line with
                GDPR's
                requirements for data minimization and purpose limitation.</p>

            <h3>Changes to This Privacy Policy</h3>
            <p>We reserve the right to update this privacy policy at any time. Changes will be effective immediately
                upon posting to our website.</p>

            <h3>Contact Information</h3>
            <p>For any questions or requests regarding your data, please contact us at <a
                href="mailto:support@burnoutornot.com">support@burnoutornot.com</a>. support hours: Monday to Friday
                18:30
                to 22:30 CET via email.</p>

            <h3>Jurisdiction</h3>
            <p>This privacy policy is governed by the laws of Germany, and the designated forum and court for any
                disputes arising from this policy are located in Berlin, Germany.</p>

            <p>By using Burnoutornot.com, you acknowledge that you have read and understood this privacy policy and
                agree to its terms.</p>
        </div>

    );
}


export default PrivacyPolicy;