import React from "react";
import {Form, Rate, Result} from 'antd';
import {FireOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

function WorkSituationN(props) {
    const {t} = useTranslation();
    let formData = JSON.parse(localStorage.getItem('formData'));
    if (formData['WorkSituationN'] === undefined) {
        formData = {...formData, ...{"WorkSituationN": {}}}
        localStorage.setItem('formData', JSON.stringify(formData));
    }

    const customIcons = {
        1: <FireOutlined/>,
        2: <FireOutlined/>,
        3: <FireOutlined/>,
        4: <FireOutlined/>,
        5: <FireOutlined/>,
    };

    return (
        <div className="questions-container">
            <Result
                status="success"
                icon={' '}
                title="Free Burnscore Test"
                subTitle="Learn yourself, and come bright!"
            />

            <div className="question">
                <h3>{t('survey.work.wn-01')}</h3>
                <Form.Item
                    name="wn-01"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.work.wn-02')}</h3>
                <Form.Item
                    name="wn-02"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.work.wn-03')}</h3>
                <Form.Item
                    name="wn-03"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.work.wn-04')}</h3>
                <Form.Item
                    name="wn-04"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>

            <div className="question">
                <h3>{t('survey.work.wn-05')}</h3>
                <Form.Item

                    name="wn-5"
                    rules={[
                        {
                            required: true,
                            message: t('survey.choice_validation_message'),
                        },
                    ]}
                >
                    <Rate character={({index}) => customIcons[index + 1]}/>

                </Form.Item>
            </div>
        </div>
    );

}


export default WorkSituationN;